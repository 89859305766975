import React from "react";
import './index.scss';

interface BlankFillingQuestionsProps {
    question: string;
    value: string;
    setValue: (value: string | null) => void;
}


const BlankFillingQuestions = React.forwardRef<HTMLDivElement, BlankFillingQuestionsProps>(({ question, value, setValue }, ref) => {
    const handleInputChange = (event: any) => {
        if(event.target.value) {
            setValue(event.target.value);
        } else {
            setValue(null);
        }
    }
    return (
    <div className={"blank-fill-question question"} ref={ref}>
        <div className={'question-text'}>{question}</div>
        <input className={'question-input'} placeholder={'Answer'} value={value} onChange={handleInputChange}/>
    </div>)
})

export default BlankFillingQuestions;