import React from "react";
import BlankFillingQuestions from "../BlankFillingQuestions";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";
import MultiSelectQuestions from "../MultiSelectQuestions";

interface SeventhSurveyProps {
    response: any;
    setResponse: Function
}

//父母教育第一性原理级别测评
const SeventhSurvey = React.forwardRef<HTMLDivElement, SeventhSurveyProps>(({response, setResponse}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions key='1' question={"1. 真实姓名/微信名"} ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>
        <SelectionQuestions key='2' question={"2. 身份"} options={['母亲', '父亲']} ref={el => ref.current[1] = el}
                            setValue={(value) => {
                                setResponse(2, value)
                            }} value={response[2] || null}/>
        <BlankFillingQuestions key='3' question={"3. 手机"} ref={el => ref.current[2] = el} setValue={(value) => {
            setResponse(3, value)
        }} value={response[3] || null}/>
        <BlankFillingQuestions key='4' question={"4. 职业"} ref={el => ref.current[3] = el} setValue={(value) => {
            setResponse(4, value)
        }} value={response[4] || null}/>
        <SelectionQuestions key='5' question={"5. 最高学历（含目前在读）"}
                            options={['大学本/专科', '硕士研究生', '博士/博士后', '其他']}
                            ref={el => ref.current[4] = el} setValue={(value) => {
            setResponse(5, value)
        }} value={response[5] || null}/>
        <SelectionQuestions key='6' question={"6. 家庭年收入"}
                            options={['<20万', '<40万', '<60万', '<80万', '>100万']}
                            ref={el => ref.current[5] = el} setValue={(value) => {
            setResponse(6, value)
        }} value={response[6] || null}/>
        <MultiSelectQuestions key='7' question={"7. 情绪支持 (多选题)"}
                              options={['我总是密切关注孩子的情绪变化,每天与孩子对话沟通,在发现问题后帮助他们理解和处理情绪.', '我经常关注孩子的情绪变化,但并不是每天对话,我会每周与孩子沟通,或在孩子明显不舒服时与他们进行情绪相关的对话,并提供帮助.',
                                  '我通常只在孩子明显显示出情绪困扰时才会进行情绪相关的对话.', '我从不关注孩子的情绪变化,我不懂怎么与孩子进行情绪相关的对话.', '孩子会与父母表达情感,会与父母沟通.', '孩子不表达情感喜好,不与父母沟通.']}
                              ref={el => ref.current[6] = el} setValue={(value) => {
            setResponse(7, value)
        }} value={response[7] || null}/>
        <MultiSelectQuestions key='8' question={"8. 时间管理 (多选题)"}
                              options={['我在孩子很小时就已经培养好TA的作息时间,现在不用督促,自己会按时睡觉.', '我严格规定孩子的睡眠时间以确保他们有足够的休息时间.我们有明确的作息时间表,并且坚持执行.',
                                  '我们没有固定的作息时间表,我会催孩子睡觉,但孩子的睡眠和休息时间较为不稳定.', '我几乎不关注孩子是否有充足的睡眠时间和良好的睡眠质量.']}
                              ref={el => ref.current[7] = el} setValue={(value) => {
            setResponse(8, value)
        }} value={response[8] || null}/>
        <MultiSelectQuestions key='9' question={"9. 习惯管理 (多选题)"}
                              options={['我与孩子共同讨论设定了规律的学习,爱好和作息时间等家规.', '我与孩子共同讨论设定了规律的学习,爱好和作息时间等家规.',
                                  '我们与孩子共同讨论设置了奖罚机制来保证孩子遵守要求.', '我们与孩子共同讨论设置了奖罚机制来保证孩子遵守要求.', '我们与孩子共同讨论设置了奖罚机制来保证孩子遵守要求.', '我们没有家规,有时候根据孩子的表现给予恰奖励或惩罚,但往往是我临时决定的.', '我们没有家规也没有设置奖罚机制,不罚也不奖励.']}
                              ref={el => ref.current[8] = el} setValue={(value) => {
            setResponse(9, value)
        }} value={response[9] || null}/>
        <MultiSelectQuestions key='10' question={"10. 健康 (多选题)"}
                              options={['我很关注孩子的饮食习惯,并提供营养均衡的餐食.', '我们不太注意孩子的饮食是否均衡,有什么吃什么.',
                                  '我总是教育孩子养成良好的卫生习惯,如洗手,刷牙等.', '我总是确保孩子有足够的体育活动时间.', '我几乎不关注孩子是否有足够的体育活动时间.', '我不会在意孩子是否养成良好的卫生习惯,如洗手,刷牙等.', '我从不要求孩子注意睡眠和休息,孩子的作息时间完全没有规律.']}
                              ref={el => ref.current[9] = el} setValue={(value) => {
            setResponse(10, value)
        }} value={response[10] || null}/>
        <MultiSelectQuestions key='11' question={"11. 兴趣培养 (多选题)"}
                              options={['孩子很小就开始参加各种兴趣活动,现在也在坚持（二年以上）自己喜欢的兴趣.', '我总是关注孩子的兴趣和需求,而且经常和他们一起讨论他们的感受.',
                                  '孩子有参加兴趣活动,父母没有跟孩子谈论,不知道是否喜欢,但一直坚持.', '孩子有参加兴趣活动,但不是自己喜欢的,父母强迫.', '孩子没有兴趣爱好,我几乎不关注孩子的兴趣和需求,也从不和他们一起讨论他们的感受.']}
                              ref={el => ref.current[10] = el} setValue={(value) => {
            setResponse(11, value)
        }} value={response[11] || null}/>
        <MultiSelectQuestions key='12' question={"12. 学习力 (多选题)"}
                              options={['我主动定期了解孩子在学校的学习表现和进步,并及时跟孩子沟通.', '我让孩子额外学习.',
                                  '我对成绩不在意.', '我很在意成绩,特别紧张.', '我教孩子如何高效地学习和管理时间.', '我鼓励孩子在学习中遇到困难时寻求帮助,并教会他们如何寻求帮助.', '我平时关注孩子的学习,能及时发现问题.', '看到孩子成绩下降,我总是批评孩子,不问明原因.']}
                              ref={el => ref.current[11] = el} setValue={(value) => {
            setResponse(12, value)
        }} value={response[12] || null}/>

        <MultiSelectQuestions key='13' question={"13. 目标管理 (多选题)"}
                              options={['我关注孩子的长期兴趣和优势,从小就做未来规划.', '我关注孩子的长期兴趣和优势,从小就做未来规划.',
                                  '我单方面给孩子做规划,强迫孩子执行.', '我没有做过规划.', '我不知道孩子的兴趣和优势.']}
                              ref={el => ref.current[12] = el} setValue={(value) => {
            setResponse(13, value)
        }} value={response[13] || null}/>

        <MultiSelectQuestions key='14' question={"14. 沟通-价值观传递 (多选题)"}
                              options={['我跟孩子讨论各种问题,双方各抒己见时我会听孩子的想法,并共同寻求解决方案或真理.', '我跟孩子讨论时孩子不能有异议,因为我认为孩子应该与您观点一至,不愿意听孩子的想法.',
                                  '我经常跟孩子一起看电视,看书,看电影,并一起讨论.', '我几乎不跟孩子一起看电视,看书,看电影.', '我很少跟孩子讨论,也不知道孩子的思想.', '我根本不听孩子的想法.']}
                              ref={el => ref.current[13] = el} setValue={(value) => {
            setResponse(14, value)
        }} value={response[14] || null}/>

        <MultiSelectQuestions key='15' question={"15. 自我成长 (多选题)"}
                              options={['我认为自己有成长空间,不断学习让自己跟上时代.', '我不学习,也不改变,孩子才应该学习改变.',
                                  '我会跟朋友讨论,但没有具体的学习方法.', '我经常看书,听讲座.', '我有很多朋友可以相互倾诉.', '我有很多朋友可以相互倾诉.', '我坚信自己的改变,会影响孩子.', '我觉得自己都懂,不需要学习.']}
                              ref={el => ref.current[14] = el} setValue={(value) => {
            setResponse(15, value)
        }} value={response[15] || null}/>

        <MultiSelectQuestions key='16' question={"16. 我认为上大学最重要的目的是什么? (多选题)"}
                              options={['找工作/就业.', '成长/理解我的身份.',
                                  '学习.', '认识朋友,建立人脉.', '探索社交并尝试新事物(如俱乐部,课外活动等).', '我有很多朋友可以相互倾诉.', '为未来实现财务稳定或成功.', '形成独立意见.', '拥有证书/学位', '获得批判性思维', '其他']}
                              ref={el => ref.current[15] = el} setValue={(value) => {
            setResponse(16, value)
        }} value={response[16] || null}/>

        <BlankFillingQuestions key='17' question={"17. 您做什么运动?一周几次?"} ref={el => ref.current[16] = el}
                               setValue={(value) => {
                                   setResponse(17, value)
                               }} value={response[17] || null}/>

        <BlankFillingQuestions key='18' question={"18. 您对人生的满足感是多少?0-10打分."}
                               ref={el => ref.current[17] = el}
                               setValue={(value) => {
                                   setResponse(18, value)
                               }} value={response[18] || null}/>

        <BlankFillingQuestions key='19' question={"19. 您带孩子参加过什么社交活动?列举出至少三个近期的活动."}
                               ref={el => ref.current[18] = el}
                               setValue={(value) => {
                                   setResponse(19, value)
                               }} value={response[19] || null}/>

        <BlankFillingQuestions key='20' question={"20. 在教导孩子处理人际冲突和解决问题时,给过哪些建议?至少三个."}
                               ref={el => ref.current[19] = el}
                               setValue={(value) => {
                                   setResponse(20, value)
                               }} value={response[20] || null}/>

        <BlankFillingQuestions key='21'
                               question={"21. 您如何确保自己对孩子在网络环境中的社交行为和安全有足够的了解和监管?您能分享一些您采取的具体措施吗?"}
                               ref={el => ref.current[20] = el}
                               setValue={(value) => {
                                   setResponse(21, value)
                               }} value={response[21] || null}/>

        <BlankFillingQuestions key='22' question={"22. 您是否为孩子分配适当的家务任务?如果是,孩子做什么家务?"}
                               ref={el => ref.current[21] = el}
                               setValue={(value) => {
                                   setResponse(22, value)
                               }} value={response[22] || null}/>

        <BlankFillingQuestions key='23' question={"23. 您是否让孩子参与家庭决策过程?如果是,请举三个例子."}
                               ref={el => ref.current[22] = el}
                               setValue={(value) => {
                                   setResponse(23, value)
                               }} value={response[23] || null}/>

        <BlankFillingQuestions key='24'
                               question={"24. 您是否教过孩子如何管理自己的金钱和物品?如果是,请列举教过的三个方法."}
                               ref={el => ref.current[23] = el}
                               setValue={(value) => {
                                   setResponse(24, value)
                               }} value={response[24] || null}/>

        <BlankFillingQuestions key='25' question={"25. 您是否告知孩子家庭的收支情况?孩子是否清楚家庭生活支出?"}
                               ref={el => ref.current[24] = el}
                               setValue={(value) => {
                                   setResponse(25, value)
                               }} value={response[25] || null}/>

        <BlankFillingQuestions key='26' question={"26. 您是否跟孩子讲工作上的得失?如果是,孩子有什么反应?"}
                               ref={el => ref.current[25] = el}
                               setValue={(value) => {
                                   setResponse(26, value)
                               }} value={response[26] || null}/>

        <BlankFillingQuestions key='27'
                               question={"27. 您是否有意识地向孩子传授解决问题和应对挑战的策略?如果有,您能否分享您引导孩子努力学习的三种具体技巧或方法?"}
                               ref={el => ref.current[26] = el}
                               setValue={(value) => {
                                   setResponse(27, value)
                               }} value={response[27] || null}/>

        <BlankFillingQuestions key='28' question={"28. 当孩子遇到困境时您是怎么处理的?请列举出至少一个例子?"}
                               ref={el => ref.current[27] = el}
                               setValue={(value) => {
                                   setResponse(28, value)
                               }} value={response[28] || null}/>

        <BlankFillingQuestions key='29' question={"29. 在培养孩子创新能力方面您有方法吗?如果有，请列举出至少三个例子?"}
                               ref={el => ref.current[28] = el}
                               setValue={(value) => {
                                   setResponse(29, value)
                               }} value={response[29] || null}/>

        <BlankFillingQuestions key='30'
                               question={"30. 您认为想像力重要吗?有具体做过什么来激发孩子的想像力吗?如果有请举例?"}
                               ref={el => ref.current[29] = el}
                               setValue={(value) => {
                                   setResponse(30, value)
                               }} value={response[30] || null}/>

        <BlankFillingQuestions key='31'
                               question={"31. 您认为想像力重要吗?有具体做过什么来激发孩子的想像力吗?如果有请举例?"}
                               ref={el => ref.current[30] = el}
                               setValue={(value) => {
                                   setResponse(31, value)
                               }} value={response[31] || null}/>

        <BlankFillingQuestions key='32' question={"32. 您认为哪些自己的行为对孩子的道德观念形成产生了积极影响?"}
                               ref={el => ref.current[31] = el}
                               setValue={(value) => {
                                   setResponse(32, value)
                               }} value={response[32] || null}/>

        <BlankFillingQuestions key='33' question={"33. 给孩子选择兴趣爱好时,是孩子自己选还是家长选的,为什么?具体例子?"}
                               ref={el => ref.current[32] = el}
                               setValue={(value) => {
                                   setResponse(33, value)
                               }} value={response[33] || null}/>

        <BlankFillingQuestions key='34' question={"34. 您是否为孩子创造一个温馨,安全的家庭氛围?(0-10给自己打分)"}
                               ref={el => ref.current[33] = el}
                               setValue={(value) => {
                                   setResponse(34, value)
                               }} value={response[34] || null}/>

        <BlankFillingQuestions key='35' question={"35. 您和配偶在教育孩子的一致性上可以打多少分?(0-10给自己打分)"}
                               ref={el => ref.current[34] = el}
                               setValue={(value) => {
                                   setResponse(35, value)
                               }} value={response[35] || null}/>

        <BlankFillingQuestions key='36' question={"36. 孩子会跟您提出自己的意见和需求吗?提出来的需求有多少会被您接受?"}
                               ref={el => ref.current[35] = el}
                               setValue={(value) => {
                                   setResponse(36, value)
                               }} value={response[36] || null}/>

        <BlankFillingQuestions key='37'
                               question={"37. 回想一下,是否有过因孩子个性差异(即跟别的孩子不一样)而产生的争论或不解?那时您是如何处理的?"}
                               ref={el => ref.current[36] = el}
                               setValue={(value) => {
                                   setResponse(37, value)
                               }} value={response[37] || null}/>

    </div>
})

export default SeventhSurvey;