import React from 'react';
import "./index.scss"
import { ReactComponent as ContactImage } from '../../assets/image-contact.svg';


const ContactPage = () => {
    return <div className={"contact-page"}>
        <ContactImage className={"image-contact"}/>
        <div className={"texts"}>联系方式</div>
    </div>
}

export default ContactPage;