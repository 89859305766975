import React, {useCallback, useEffect, useRef, useState} from 'react';
import "./index.scss"
import {useLocation, useNavigate} from "react-router-dom";
import {useRequest} from "ahooks";
import {getSurveyBySurveyGroupUuidAndSurveyUuid, updateResponse} from "../../requests";
import {Progress} from "antd";
import {SURVEY_TYPE_MAP} from "../../constants";
import SeventhSurvey from "./SeventhSurvey";
import EighthSurvey from "./EighthSurvey";


const Survey = () => {
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const sgid = params.get("sgid") || undefined;
    const id = params.get("id") || undefined;
    const {data, loading} = useRequest(() => getSurveyBySurveyGroupUuidAndSurveyUuid(sgid, id))
    const navigate = useNavigate();
    const surveyIndex = data?.user_type === 'PARENT' ? data?.survey_type - 6 : data?.survey_type;
    const [progress, setProgress] = useState(0);
    const [response, setResponse] = useState({});
    const questionRefs = useRef([]);
    const updateReponse = async () => {
        await updateResponse(id, response)
    }

    useEffect(() => {
        if(!loading) {
            setResponse(data.response)
        }
    }, [loading]);

    useEffect(() => {
        console.log(response)
    }, [response]);

    const handleBeforeUnload = useCallback((event: any) => {
        const confirmationMessage = "Are you sure you want to leave this page? Unsaved changes might be lost.";

        event.preventDefault();
        event.returnValue = confirmationMessage; // For modern browsers
        return confirmationMessage; // For older browsers
    },[]);


    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => window.removeEventListener("beforeunload", handleBeforeUnload);
    }, [handleBeforeUnload]);


    const handleResponseChange = (index: number, answer: string | null) => {
        setResponse(prevResponses => {
            const newResponses = {...prevResponses};
            if (answer) {
                // @ts-ignore
                newResponses[index] = answer;
            } else {
                // @ts-ignore
                delete newResponses[index];
            }
            return newResponses;
        });
    }

    const renderContent = (ref: any) => {
        switch (data.survey_type) {
            case "default":
                return <div>default</div>
            case 1:
                return <div>1</div>
            case 2:
                return <div>2</div>
            case 3:
                return <div>3</div>
            case 4:
                return <div>4</div>
            case 5:
                return <div>5</div>
            case 6:
                return <div>6</div>
            case 7:
                return <SeventhSurvey ref={ref} response={response} setResponse={handleResponseChange}/>

            case 8:
                return <EighthSurvey ref={ref} response={response} setResponse={handleResponseChange}/>

            default:
                navigate('/404', {replace: true});
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // @ts-ignore
                    const index = questionRefs.current.indexOf(entry.target as HTMLDivElement);
                    if (index !== -1) {
                        setProgress(index + 1);
                    }
                }
            });
        }, {
            threshold: 1 // Adjust this threshold as needed
        });

        questionRefs.current.forEach(question => {
            if (question) observer.observe(question);
        });

        return () => {
            questionRefs.current.forEach(question => {
                if (question) observer.unobserve(question);
            });
        };
    }, [data]);





    return <div className={"survey-container"}>
        {data && (<>
            {/*@ts-ignore*/}
            <Progress percent={Object.keys(data?.response).length / SURVEY_TYPE_MAP[data?.survey_type].total * 100} strokeColor={"#DA1E28"} trailColor={"\n" +
                "rgba(193, 18, 31, 0.27)"} size={{height: 36}} className={"progress-bar"}
                      showInfo={false}/>
            <div className={"survey-header"}>问卷{surveyIndex}</div>
            {/*@ts-ignore*/}
            <div className={"survey-title"}>{SURVEY_TYPE_MAP[data?.survey_type].name}</div>
            <div className={'survey-content'}>
                <div className={"total-count"}>
                    <span className={'current-progress'}>{progress}</span>
                    {/*@ts-ignore*/}
                    <span className={'total-progress'}>/{SURVEY_TYPE_MAP[data?.survey_type].total} </span>
                </div>
                {renderContent(questionRefs)}
                <div className={"buttons"}>
                    <div className={'button-text'} onClick={async ()=> {await updateReponse()}}>保存进度</div>
                    <div className={'button-text'} onClick={async ()=> {await updateReponse();  window.removeEventListener("beforeunload", handleBeforeUnload); window.close();}}>保存并退出</div>
                </div>
            </div>

        </>)}
    </div>
}

export default Survey;