import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Header from "./components/Header";
import SurveyContainer from "./components/SurveyContainer";
import NotFound from "./components/NotFound";
import Survey from "./components/Survey";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <div
            className={`element-container ${window.location.pathname === '/survey' ? 'element-container-survey' : ''}`}>
            <Header/>
            <Router>
                <Routes>
                    <Route path="/" element={<div>Hello world!ees</div>}/>
                    <Route path="/sg" element={<SurveyContainer/>}/>
                    <Route path="/survey" element={<Survey/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </div>
    </React.StrictMode>
);

