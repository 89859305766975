import React from "react";
import BlankFillingQuestions from "../BlankFillingQuestions";
import './index.scss'
import SelectionQuestions from "../SelectionQuestions";

interface EighthSurveyProps {
    response: any;
    setResponse: Function
}

//爱的五种语言（成年人）
const EighthSurvey = React.forwardRef<HTMLDivElement, EighthSurveyProps>(({response, setResponse}, ref: any) => {
    return <div className={"survey-question"}>
        <BlankFillingQuestions key='1' question={"1. 真实姓名/微信名"} ref={el => ref.current[0] = el}
                               setValue={(value) => {
                                   setResponse(1, value)
                               }} value={response[1] || null}/>
        <BlankFillingQuestions key='2' question={"2. 手机"} ref={el => ref.current[1] = el}
                               setValue={(value) => {
                                   setResponse(2, value)
                               }} value={response[2] || null}/>
        <SelectionQuestions key='3' question={"3. 性别"} options={['男', '女']} ref={el => ref.current[2] = el}
                            setValue={(value) => {
                                setResponse(3, value)
                            }} value={response[3] || null}/>

        <SelectionQuestions key='4' question={"4. A 我喜欢收到那些称赞我的字条 \n \u00A0\u00A0\u00A0 E 我喜欢被拥抱"}
                            options={['A', 'E']} ref={el => ref.current[3] = el}
                            setValue={(value) => {
                                setResponse(4, value)
                            }} value={response[4] || null}/>


        <SelectionQuestions key='5' question={"5. B 我愿意花时间与我看为重要的人单独在一起 \n \u00A0\u00A0\u00A0 D 当别人给予我实际帮助时,我感觉被爱"}
                            options={['B', 'D']} ref={el => ref.current[4] = el}
                            setValue={(value) => {
                                setResponse(5, value)
                            }} value={response[5] || null}/>

        <SelectionQuestions key='6' question={"6. C 我愿意收到礼物 \n \u00A0\u00A0\u00A0 B 我喜欢朋友或亲人的探访"}
                            options={['C', 'B']} ref={el => ref.current[5] = el}
                            setValue={(value) => {
                                setResponse(6, value)
                            }} value={response[6] || null}/>

        <SelectionQuestions key='7' question={"7. D 当别人给予我帮助时,我感觉被爱 \n \u00A0\u00A0\u00A0 E 当朋友或亲人触摸我时,我感觉被爱(如:手搭肩膀,拥抱等)"}
                            options={['D', 'E']} ref={el => ref.current[6] = el}
                            setValue={(value) => {
                                setResponse(7, value)
                            }} value={response[7] || null}/>

        <SelectionQuestions key='8' question={"8. E 当我欣赏/仰慕的人将他/她的手搭在我肩膀时,我感觉被爱 \n \u00A0\u00A0\u00A0 C 当我欣赏/仰慕的人送我礼物时,我感觉被爱"}
                            options={['E', 'C']} ref={el => ref.current[7] = el}
                            setValue={(value) => {
                                setResponse(8, value)
                            }} value={response[8] || null}/>

        <SelectionQuestions key='9' question={"9. B 我喜欢与我的朋友或者所爱的人到处去旅行 \n \u00A0\u00A0\u00A0 E 我喜欢和对我特别的朋友牵手或者击掌(HIGH -FIVE)"}
                            options={['B', 'E']} ref={el => ref.current[8] = el}
                            setValue={(value) => {
                                setResponse(9, value)
                            }} value={response[9] || null}/>

        <SelectionQuestions key='10' question={"10. C 礼物对我来说很重要 \n \u00A0\u00A0\u00A0\u00A0 A 当别人给予我肯定时，我感觉被爱"}
                            options={['C', 'A']} ref={el => ref.current[9] = el}
                            setValue={(value) => {
                                setResponse(10, value)
                            }} value={response[10] || null}/>

        <SelectionQuestions key='11' question={"11. E 我喜欢坐靠近那些我喜欢的人 \n \u00A0\u00A0\u00A0\u00A0 A 我喜欢听到别人说我美丽/英俊"}
                            options={['E', 'A']} ref={el => ref.current[10] = el}
                            setValue={(value) => {
                                setResponse(11, value)
                            }} value={response[11] || null}/>

        <SelectionQuestions key='12' question={"12. B 我喜欢花时间和我的亲人或者朋友在一起 \n \u00A0\u00A0\u00A0\u00A0 C 我喜欢收到朋友或者亲人所送的小礼物"}
                            options={['B', 'C']} ref={el => ref.current[11] = el}
                            setValue={(value) => {
                                setResponse(12, value)
                            }} value={response[12] || null}/>

        <SelectionQuestions key='13' question={"13. A 话语上的肯定对我来说十分的重要 \n \u00A0\u00A0\u00A0\u00A0 D 我知道当别人帮助我时,他们是爱我的"}
                            options={['A', 'D']} ref={el => ref.current[12] = el}
                            setValue={(value) => {
                                setResponse(13, value)
                            }} value={response[13] || null}/>

        <SelectionQuestions key='14' question={"14. B 我喜欢与朋友或者亲人一起做事情 \n \u00A0\u00A0\u00A0\u00A0\u00A0 A 我喜欢听到别人对我说有爱心的话"}
                            options={['B', 'A']} ref={el => ref.current[13] = el}
                            setValue={(value) => {
                                setResponse(14, value)
                            }} value={response[14] || null}/>

        <SelectionQuestions key='15' question={"15. D 对我来说别人所做的比他/她所说的来得重要 \n\u00A0\u00A0\u00A0\u00A0\u00A0 E 拥抱对我来说让我觉得有价值,并被看重"}
                            options={['D', 'E']} ref={el => ref.current[14] = el}
                            setValue={(value) => {
                                setResponse(15, value)
                            }} value={response[15] || null}/>

        <SelectionQuestions key='16' question={"16. A 我珍惜称赞,我也尽量躲避批判 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 我会珍惜几份不昂贵的礼物多过一份很贵重的礼物"}
                            options={['A', 'C']} ref={el => ref.current[15] = el}
                            setValue={(value) => {
                                setResponse(16, value)
                            }} value={response[16] || null}/>

        <SelectionQuestions key='17' question={"17. B 当我与朋友或者亲人一起做/讲一些事情时,我感觉我们很靠近 \n\u00A0\u00A0\u00A0\u00A0\u00A0 E 当朋友或者亲人触摸我时,我会感觉和他们比较亲近"}
                            options={['B', 'E']} ref={el => ref.current[16] = el}
                            setValue={(value) => {
                                setResponse(17, value)
                            }} value={response[17] || null}/>

        <SelectionQuestions key='18' question={"18. A 我喜欢别人称赞我的成就 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当别人为我做一些他/她不喜欢做的事情时,我知道那是因为他/她爱我"}
                            options={['A', 'D']} ref={el => ref.current[17] = el}
                            setValue={(value) => {
                                setResponse(18, value)
                            }} value={response[18] || null}/>

        <SelectionQuestions key='19' question={"19. E 当朋友靠近我时，我喜欢他们触碰我 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 我喜欢当别人全神贯注的听我说话"}
                            options={['E', 'B']} ref={el => ref.current[18] = el}
                            setValue={(value) => {
                                setResponse(19, value)
                            }} value={response[19] || null}/>

        <SelectionQuestions key='20' question={"20. D 当朋友或者家人在工作或者其他的事情上帮助我,我会感觉被爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 我喜欢收到朋友的礼物"}
                            options={['D', 'C']} ref={el => ref.current[19] = el}
                            setValue={(value) => {
                                setResponse(20, value)
                            }} value={response[20] || null}/>

        <SelectionQuestions key='21' question={"21. A 我喜欢别人称赞我的外表 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 当别人愿意花时间来明白我的感受时,我会感觉被爱"}
                            options={['A', 'B']} ref={el => ref.current[20] = el}
                            setValue={(value) => {
                                setResponse(21, value)
                            }} value={response[21] || null}/>

        <SelectionQuestions key='22' question={"22. E 当一些特别的人触摸我时,我感觉安全 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当别人愿意为我做事时,我感觉他们爱我"}
                            options={['E', 'D']} ref={el => ref.current[21] = el}
                            setValue={(value) => {
                                setResponse(22, value)
                            }} value={response[22] || null}/>

        <SelectionQuestions key='23' question={"23. D 我感激他人为我所做的事 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 我喜欢对我来说特别的人做给我的礼物"}
                            options={['D', 'C']} ref={el => ref.current[22] = el}
                            setValue={(value) => {
                                setResponse(23, value)
                            }} value={response[23] || null}/>

        <SelectionQuestions key='24' question={"24. B 我真的享受他人给予我的那份全神贯注的时刻 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当他人能为我服务时,我真的感觉很快乐"}
                            options={['B', 'D']} ref={el => ref.current[23] = el}
                            setValue={(value) => {
                                setResponse(24, value)
                            }} value={response[24] || null}/>

        <SelectionQuestions key='25' question={"25. C 当别人送我生日礼物时,我感觉被爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 当别人以正面的字句(书面/话语)来为我庆祝生日时,我感觉被爱"}
                            options={['C', 'A']} ref={el => ref.current[24] = el}
                            setValue={(value) => {
                                setResponse(25, value)
                            }} value={response[25] || null}/>

        <SelectionQuestions key='26' question={"26. C 我知道当他人送我礼物时,他/她正念着我 \n\u00A0\u00A0\u00A0\u00A0\u00A0 D 当别人愿意帮助我分担我需要做的事情时,我感觉被爱"}
                            options={['C', 'D']} ref={el => ref.current[25] = el}
                            setValue={(value) => {
                                setResponse(26, value)
                            }} value={response[26] || null}/>

        <SelectionQuestions key='27' question={"27. B 当别人耐心听我说话而且不打岔,我会感觉开心 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 当别人在特别的日子里送我礼物,我会感觉开心"}
                            options={['B', 'C']} ref={el => ref.current[26] = el}
                            setValue={(value) => {
                                setResponse(27, value)
                            }} value={response[27] || null}/>

        <SelectionQuestions key='28' question={"28. D 我喜欢我家人/朋友能在日常的事情上帮助我以代表他们对我的关心 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 我享受能与朋友/家人去旅行"}
                            options={['D', 'B']} ref={el => ref.current[27] = el}
                            setValue={(value) => {
                                setResponse(28, value)
                            }} value={response[28] || null}/>

        <SelectionQuestions key='29' question={"29. E 我享受被我亲密的人(家人/好朋友等)拥抱或者去拥抱他们 \n\u00A0\u00A0\u00A0\u00A0\u00A0 C 在没有特别的日子里收到礼物能使我兴奋"}
                            options={['E', 'C']} ref={el => ref.current[28] = el}
                            setValue={(value) => {
                                setResponse(29, value)
                            }} value={response[29] || null}/>

        <SelectionQuestions key='30' question={"30. A 我喜欢听到他人告诉我他们欣赏我 \n\u00A0\u00A0\u00A0\u00A0\u00A0 B 我喜欢他人能在说话时候看着我"}
                            options={['A', 'B']} ref={el => ref.current[29] = el}
                            setValue={(value) => {
                                setResponse(30, value)
                            }} value={response[30] || null}/>

        <SelectionQuestions key='31' question={"31. C 朋友或者家人送的礼物对我来说都非常珍贵 \n\u00A0\u00A0\u00A0\u00A0\u00A0 E 朋友或亲爱的人碰触我时,我感觉非常的好"}
                            options={['C', 'E']} ref={el => ref.current[30] = el}
                            setValue={(value) => {
                                setResponse(31, value)
                            }} value={response[31] || null}/>

        <SelectionQuestions key='32' question={"32. D 当别人十分热情的为我做事情时，我感觉被爱 \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 当别人对我说他们十分欣赏时,我感觉被爱"}
                            options={['D', 'A']} ref={el => ref.current[31] = el}
                            setValue={(value) => {
                                setResponse(32, value)
                            }} value={response[32] || null}/>

        <SelectionQuestions key='33' question={"33. E 我需要每天被触摸(如拥抱，拍拍肩膀) \n\u00A0\u00A0\u00A0\u00A0\u00A0 A 我需要他人每天对我说肯定我的话"}
                            options={['E', 'A']} ref={el => ref.current[32] = el}
                            setValue={(value) => {
                                setResponse(33, value)
                            }} value={response[33] || null}/>
    </div>
})

export default EighthSurvey;