// @ts-nocheck
import {message} from "antd";

export const request = async (url = '', options = {}) : Promise<any> => {
    const token = localStorage.getItem('token') || undefined;
    const tokenString = token ? `Bearer ${token}` : undefined;
    const {
        headers: headersParams = {},
        config = {},
        ignored = false,
        textResponse = false,
        ...rest
    } = options;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': tokenString,
        ...headersParams,
    };

    Object.entries(headers).forEach(([key, value]) => {
        // remove undefined headers
        if (value === undefined) delete headers[key];
    });

    console.log('process.env.REACT_APP_HOST_URL: ', process.env.REACT_APP_HOST_URL)
    return new Promise((resolve, reject) => {
        fetch(ignored ? url : 'https://yutongguihua.com/api/' + url, {
            method: 'GET',
            ...rest,
            headers,
        })
            .then((res) => {
                if (config?.noHandler) resolve(res);
                return res;
            })
            .then((res) => {
                const status = parseInt(res?.status, 10);
                if (res === undefined) {
                } else if (status !== 200) {
                    message.error(result?.message)
                }
                return res;
            }) // based on response.status
            .then((response) => {
                if (textResponse) {
                    return response?.text();
                }
                return response?.json();
            })
            .then((res) => {
                resolve(res);
                return res;
            })
            .catch((err) => {
                reject({
                    ...err.res,
                    message: err.message,
                });
            });
    });
}
