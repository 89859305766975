import React from 'react';
import "./index.scss"
import {Progress, Table, TableProps} from "antd";
import {SURVEY_TYPE_MAP} from "../../constants";
import {useRequest} from "ahooks";
import {getSurveysBySurveyGroupId} from "../../requests";
import {useLocation} from "react-router-dom";


const SurveyList = () => {
    interface DataType {
        key: number;
        index: number;
        name: string;
        progress: number;
    }

    interface SurveySimpleInfo {
        id: string,
        progress: number,
        survey_type: number,
        user_type: string,
        total: number
    }

    const columns: TableProps<DataType>['columns'] = [
        {
            title: '问卷',
            dataIndex: 'index',
            key: 'index',
            render: (index) => <div className={'index-text'}>No.{index}</div>,
        },
        {
            title: '测评',
            dataIndex: 'name',
            key: 'name',
            render: (name) => <div className={'name-text'}>{name}</div>,
        },
        {
            title: '进度',
            dataIndex: 'progress',
            key: 'progress',
            render: (progress, obj) => {
               return (<div>
                    <Progress percent={progress / 40 * 100} strokeColor={"#DA1E28"} trailColor={"\n" +
                        "rgba(193, 18, 31, 0.27)"} size={{width: 489, height: 32}} className={"progress-bar"}
                              showInfo={false}/>
                   {/*@ts-ignore*/}
                    <span className={"progress-text"}>{progress}/{obj.total}</span>
                </div>)
            }
        }
    ]
    const location = useLocation();
    const params = new URLSearchParams(location?.search);
    const uuid = params.get("uuid") || undefined;
    // @ts-ignore
    const {data: surveys} = useRequest(() => getSurveysBySurveyGroupId(uuid))

    const items = surveys?.map(((u: SurveySimpleInfo, i: number) => {
        return {
            key: u.id,
            index: i + 1,
            name: SURVEY_TYPE_MAP[u.survey_type as keyof typeof SURVEY_TYPE_MAP].name,
            progress: u.progress,
            total: SURVEY_TYPE_MAP[u.survey_type as keyof typeof SURVEY_TYPE_MAP].total,
        }
    }))


    return <div className={"out-container"}>
        <div className={'list-container'}>
            <Table columns={columns}
                   dataSource={items}
                   pagination={false}
                   className={"survey-list__table"}
                   rowClassName={"survey-list__table-row"}
                   onRow={(record) => {
                       return {
                           onClick: () => {
                               window.open(`/survey?id=${record.key}&sgid=${uuid}`, '_blank');
                           },
                       };
                   }}/>
        </div>
    </div>
}

export default SurveyList;