import React, {useState} from "react";
import './index.scss';
import {ReactComponent as UncheckedSVGBlue} from '../../../assets/check-box-uncheck-blue.svg';
import {ReactComponent as CheckedSVGBlue} from '../../../assets/check-box-checked-blue.svg';
import {ReactComponent as UncheckedSVGRed} from '../../../assets/check-box-uncheck-red.svg';
import {ReactComponent as CheckedSVGRed} from '../../../assets/check-box-checked-red.svg';


interface SelectionQuestionsProps {
    question: string;
    options: Array<String>;
    value: string;
    setValue: (value: any) => void;
}

interface AnimatedCheckboxProps {
    checked: boolean;
    setChecked: (value: boolean) => void;
    animating: boolean;
    setAnimating: (value: boolean) => void;
    color: string;
}

const AnimatedCheckbox: React.FC<AnimatedCheckboxProps> = ({checked, setChecked, animating, setAnimating, color}) => {
    const handleClick = () => {
        if (!animating) {
            setAnimating(true);
            setTimeout(() => {
                setChecked(!checked);
                setTimeout(() => {
                    setAnimating(false);
                }, 300);
            }, 300);
        }
    };

    return (
        <div onClick={handleClick} className={`checkbox ${animating ? 'animating' : 'normal'}`}>
            {color === 'red' ? (checked ? <CheckedSVGRed/> : <UncheckedSVGRed/>) : (checked ? <CheckedSVGBlue/> : <UncheckedSVGBlue/>)}
        </div>
    );
};


const SelectionQuestions = React.forwardRef<HTMLDivElement, SelectionQuestionsProps>(({question, options, setValue, value}, ref) => {
    const initCheckboxValue = options.map(option => {
            return ({optionValue: option, animating: false})
        })
        const [checkboxes, setCheckboxes] = useState(initCheckboxValue);


    const handleSetChecked = (index: number, v: boolean) => {
        if(v) {
            //@ts-ignore
            setValue(checkboxes[index].optionValue)
        } else {
            setValue(null)
        }
    };


        const handleSetAnimating = (index: number, value: boolean) => {
            const newCheckboxes = [...checkboxes];
            newCheckboxes[index].animating = value;
            setCheckboxes(newCheckboxes);
        };

        return (
            <div className={"selection-question question"} >
                <div className={'question-text'} ref={ref}>{question}</div>
                {checkboxes.map((checkbox, index) => (
                    <div key={index} className={'option'}>
                        <div className="checkbox-container">
                            <AnimatedCheckbox
                                checked={checkbox.optionValue === value}
                                setChecked={(v) => {handleSetChecked(index, v)}}
                                animating={checkbox.animating}
                                setAnimating={(value) => handleSetAnimating(index, value)}
                                color={index % 2 === 0 ? 'red':'blue'}
                            />
                        </div>
                        <span className={'option-text'}>{options[index]}</span>
                    </div>
                ))}
            </div>
        )
    }
)

export default SelectionQuestions;