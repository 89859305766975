import {request} from "./request";


export const getSurveysBySurveyGroupId = (surveyGroupId: string) => {
    return request(`survey/get-surveys?uuid=${surveyGroupId}`,);

}

export const getSurveyBySurveyGroupUuidAndSurveyUuid = (sgid: string | undefined, id: string | undefined) => {
    return request(`survey/get-survey?sgUuid=${sgid}&uuid=${id}`,);
}

export const updateResponse = (uuid: string | undefined, response: object) => {
    return request(`survey/response?uuid=${uuid}`, {method: 'PATCH', body: JSON.stringify({response})});
}